<script>
import { Pie } from 'vue-chartjs'
import { PieChartOptions } from './chartOptions'
import { mapState } from 'vuex'
import CustomChartMethods from './customChartMethods'
export default {
  name: 'PieChart',
  extends: Pie,
  props: ['formData'],
  data () {
    return {
      options: PieChartOptions
    }
  },
  computed: {
    ...mapState({
      chartSampleData: (state) => state.widgets.chartSampleData
    })
  },
  mounted () {
    this.plotChart()
  },
  methods: {
    destroyChart () {
      if (this.$data._chart) {
        this.$data._chart.destroy()
      }
    },
    plotChart () {
      this.destroyChart()
      const chartData = new CustomChartMethods().getChartData(
        this.formData,
        this.chartSampleData
      )
      this.renderChart(chartData, this.options)
    }
  },
  destroyed () {
    this.destroyChart()
  },
  watch: {
    chartSampleData (newValue, oldValue) {
      this.plotChart()
    },
    'formData.colorSchemeLst' (newValue, oldValue) {
      this.plotChart()
    }
  }
}
</script>
